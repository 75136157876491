export const ArrowOut = (): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1551_3685"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1551_3685)">
        <path
          d="M6.40004 17.6442L5.35583 16.6L15.1962 6.74995H6.25006V5.25H17.75V16.75H16.2501V7.80378L6.40004 17.6442Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
