import { ArrowOut } from "../../icons/arrow-out";
import { ArrowOutward } from "../../icons/arrow-outward";
import { BarChart } from "../../icons/bar-chart";
import { CreditCard } from "../../icons/credit-card";
import Accordion from "../Accordion";
import "./Home.css";

const Home = (): React.ReactElement => (
  <div>
    <div className="bl-hero w-screen flex flex-col items-center">
      <header className="flex justify-between bl-container py-8 w-full">
        <a href="/" data-testid="homeLink">
          <img
            src="images/logo.svg"
            alt="Bloom Logo"
            className="w-32 sm:w-44"
          />
        </a>
        <span className="">
          <a href="https://bloompulse.app/signin">
            <span className="link mr-4">Login</span>
          </a>
          <a href="https://form.jotform.com/231903484150047" target="_blank" rel="noreferrer">
            <button className="rounded-full text-center text-white hover:to-primary lg:inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3 hidden">
              Get early access <ArrowOut />
            </button>
          </a>
        </span>
      </header>
      <div className="flex bl-container pt-16 pb-24 flex-col lg:flex-row">
        <div className="lg:w-1/2 flex space-y-8 flex-col justify-center pb-8 lg:pb-0 lg:pr-8 text-center lg:text-left">
          <h2 className="text-2xl lg:text-5xl font-extrabold">
            Simple & smart way to monitor the health of your business.
          </h2>
          <p data-testid="subtitle" className="text-base md:text-lg">
            Know when you break even, track expenses, record sales, production
            materials and more - all in one place.
          </p>
          <span className="flex flex-col lg:flex-row space-y-4">
            <a href="https://form.jotform.com/231903484150047" target="_blank" rel="noreferrer">
              <button className="rounded-full text-center text-white hover:to-primary inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3">
                Get early access <ArrowOut />
              </button>
            </a>
            {/* <span className="link ml-4">Watch 1 minute demo</span> */}
          </span>
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <div className="relative flex justify-center items-center max-w-md">
            <img src="images/hero-tailor.png" className="" alt="" />
            <img
              src="images/hero-chart2.svg"
              className="absolute left-0 right-0 w-full"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col items-center justify-center rounded-3xl bg-white -mt-10 -mb-10 pb-20 relative">
      <div className="bl-section">
        <img src="images/overview.svg" alt="" className="bl-section-img" />
        <div className="bl-section-desc">
          <BarChart />
          <h4 className="font-bold text-2xl lg:text-3xl">
            One glance overview
          </h4>
          <p className="text-grey-50">
            Get a concise and visual summary of your business health, enabling
            you to quickly grasp the most important insights at a glance.
          </p>
        </div>
      </div>
      <div className="bl-section">
        <div className="bl-section-desc">
          <CreditCard />
          <h4 className="font-bold text-2xl lg:text-3xl">
            Cost price vs selling price
          </h4>
          <p className="text-grey-50">
            Properly account for all your production cost, expenses and other
            costs in order to generate the potential profit on each sale.
          </p>
        </div>
        <img src="images/price.svg" alt="" className="bl-section-img" />
      </div>
      <div className="bl-section">
        <img src="images/expenses.svg" alt="" className="bl-section-img" />
        <div className="bl-section-desc">
          <ArrowOutward />
          <h4 className="font-bold text-2xl lg:text-3xl">
            Track your expenses
          </h4>
          <p className="text-grey-50">
            Record and monitor all financial transactions to gain a clear
            understanding of where money is being spent and to manage budgeting
            effectively.
          </p>
        </div>
      </div>
      <div className="bl-section !border-b-0">
        <div className="bl-section-desc">
          <CreditCard />
          <h4 className="font-bold text-2xl lg:text-3xl">Orders & payments</h4>
          <p className="text-grey-50">
            Document the details of your orders and Bloompulse will
            automatically generate the production cost to help you accurately
            fulfil orders and determine profit.
          </p>
        </div>
        <img src="images/orders.svg" alt="" className="bl-section-img" />
      </div>
    </div>
    <div className="bl-hero-bt w-screen flex flex-col items-center pt-16 pb-10">
      <div className="flex flex-col items-center lg:w-1/2 pt-16 pb-24 space-y-8 p-8">
        <h4 data-testid="title" className="text-2xl font-extrabold text-center">
          See what Bloompulse can do for your business.
        </h4>
        <button className="rounded-full text-center text-white hover:to-primary inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3">
          Get early access <ArrowOut />
        </button>
      </div>
    </div>
    <div className="bg-dark-grey text-white rounded-t-3xl py-4 lg:p-20 -mt-10 flex flex-col items-center">
      <div className="bl-container flex flex-col items-center">
        <h2 className="text-2xl lg:text-5xl font-extrabold">
          Frequently asked questions
        </h2>
        <div className="pt-10">
          <Accordion title="How and when can I get access?">
            Once you fill out the early access request, we will get back to you as soon as possible. We typically respond within 24 hours.
          </Accordion>
          <Accordion title="Why is there a waitlist?">
            Beginning with a smaller user base enables us to provide personalized support, quick responses, and an intuitive user experience.
            This helps us gather valuable feedback and make necessary improvements based on real experiences, ensuring the best possible product for our users.
          </Accordion>
          <Accordion title="Does it cost money?">
            Our goal is to support the growth of your business responsibly and profitably. There is a monthly subscription fee of $8, that only applies when your business reaches a profitable month or records 50 order units, whichever comes first.
            <br />This fee covers a business with up to 3 members. Additional members will incur a cost of $8 per month per member.
          </Accordion>
          <Accordion title="Is my data secure when I use Bloompulse?">
            Your data's confidentiality and integrity are our top priority, and we continuously update our security measures to ensure it remains safe.
          </Accordion>
        </div>
      </div>
      <p className="text-grey-30 text-center pt-10">
        © 2023 Bloompulse. All rights reserved.
      </p>
    </div>
  </div>
);

export default Home;
