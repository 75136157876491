export const ArrowOutward = (): React.ReactElement => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1581_4247"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="41"
      >
        <rect width="41" height="41" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1581_4247)">
        <path
          d="M10.9333 30.1421L9.14941 28.3583L25.9601 11.5312H10.677V8.96875H30.3228V28.6145H27.7604V13.3314L10.9333 30.1421Z"
          fill="#00A89D"
        />
      </g>
    </svg>
  );
};
